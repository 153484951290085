import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import {
  Heading, SubHeading, Rel, Panel, Container, FlexLayout, Icon,
} from '../../elements';
import Back from '../../components/Back';
import Card from '../../components/Card';
import { ItunesBadge, GooglePlayBadge } from '../../components/AppBadges';
import AppContainer from '../../components/AppContainer';
import IconList from '../../components/IconList';
import PreviewCompatibleImage from '../../components/PreviewCompatibleImage';

const BigIconContainer = styled.div`
  font-size: 5rem;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%;
  padding: 2rem;
  color: ${props => props.theme.red};
`;
const BadgesContainer = styled.section`
  display: flex;
  margin-bottom: 2rem;
  a:first-child {
    margin-right: 1rem;
  }
`;

const BigIcon = ({ component }) => (
  <BigIconContainer>
    <Icon>{component}</Icon>
  </BigIconContainer>
);

const AjnApp = ({ data }) => {
  const { contentfulPage: page } = data;
  return (
    <AppContainer pageTitle={page.title}>
      <Container id="content">
        <Panel>
          <Back to="/" />
          {page.sections
            && page.sections.map(section => (
              <React.Fragment key={section.id}>
                <Heading ariaRole="heading">{page.heading}</Heading>
                <SubHeading>{page.subHeading}</SubHeading>
                <IconList list={section.list} />
                <BadgesContainer>
                  <ItunesBadge />
                  <GooglePlayBadge />
                </BadgesContainer>
                <FlexLayout>
                  {section.items
                    && section.items.map((item, i) => (
                      <Card key={i} item={item} component={BigIcon} />
                    ))}
                </FlexLayout>
              </React.Fragment>
            ))}
        </Panel>
        <Rel style={{ minHeight: 650, backgroundColor: 'white' }}>
          <PreviewCompatibleImage
            cover
            imgStyle={{ objectPosition: 'top left', maxWidth: 800 }}
            imageInfo={page.image}
          />
        </Rel>
      </Container>
    </AppContainer>
  );
};

export const ajnAppPageQuery = graphql`
  query AjnAppPageQuery($id: String!) {
    contentfulPage(id: { eq: $id }) {
      title
      slug
      heading
      image {
        fluid(maxWidth: 800) {
          ...GatsbyContentfulFluid
        }
      }
      sections {
        id
        heading
        subHeading
        list {
          title
          listItem {
            text {
              text
            }
            icon
          }
        }
        items {
          label
          infoList
          extraInfo
          component
        }
      }
    }
  }
`;

export default AjnApp;
