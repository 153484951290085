import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Text } from '../../elements';
import PreviewCompatibleImage from '../PreviewCompatibleImage';

export const CardContainer = styled.article`
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-basis: 25%;
  margin-right: 1rem;
  margin-bottom: 1rem;
  min-width: 185px;
  max-width: ${props => (props.fullWidth ? '100%' : '300px')};
  color: ${props => props.theme.dark};
  background-color: white;
  border: 1px solid ${props => props.theme.bluegrey};
  &:hover {
    background-color: white;
    border: 1px solid ${props => props.theme.primary};
  }
  @media screen and (max-width: 420px) {
    /* margin-right: 0;
    margin-bottom: 2rem; */
    max-width: 50%;
    min-width: 45%;
    &:nth-of-type(even) {
      margin-right: 0;
    }
    div, footer {
      padding: 0.65rem;
    }
    h2 {
      font-size: 1rem;
    }
    p {
      font-size: 0.85rem;
      line-height: 1.3;
    }
    span {
      font-size: 1rem;
    }
  }
`;

const ContentBox = styled.div`
  padding: 1rem;
  border-top: 1px solid ${props => props.theme.bluegrey};
`;
const ContentFooter = styled.footer`
  padding: 0 1rem 1rem 1rem;
`;
const Title = styled.h2`
  font-size: 1.35rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;
const Info = styled.p`
  font-size: 0.97rem;
  margin: 0.5rem 0rem;
  white-space: pre-line;
`;
const Price = styled.span`
  font-size: 1.3rem;
  font-weight: normal;
  color: ${props => props.theme.primary};
`;
const CardText = styled(Text)`
  font-size: 0.9rem;
  line-height: 1.4;
`;
const Header = styled.header`
  color: white;
`

const Card = ({ item, component: Component, fullWidth }) => {
 return (
  <CardContainer fullWidth={fullWidth}>
    <Header>
      {Component && <Component component={item.component} image={item.image} item={item} />}
    </Header>
    <ContentBox>
      <Title>{item.label}</Title>
      {item.text && <CardText dangerouslySetInnerHTML={{ __html: item.text.text }} />}
      {item.infoList &&
        item.infoList.map((info, i) => <Info key={i} dangerouslySetInnerHTML={{ __html: info }} />)}
    </ContentBox>
    <ContentFooter>
      <Price>{item.extraInfo}</Price>
    </ContentFooter>
  </CardContainer>
);
      }

Card.propTypes = {
  item: PropTypes.shape({
    label: PropTypes.string.isRequired,
    infoList: PropTypes.arrayOf(PropTypes.string),
    component: PropTypes.string,
    extraInfo: PropTypes.string,
  }).isRequired,
};
export default Card;
